import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../../layout/layout"
import SEO from "../../components/seo"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Hero from "../../components/hero"
import HeroBackgroundColor from "src/components/hero/background-color"
import SubsectionHero from "../../components/subsection-hero"
import CardVertical from "../../components/card-vertical"
import PageWrapper from "../../components/page-wrapper"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import content from "../../locales/en/about-sustainability.json"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "120px",
  },
  header: {
    width: "70%",
    margin: "48px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  gridContainer: {
    marginBottom: "48px",
    "@media (max-width:768px)": {
      marginBottom: "24px",
    },
  },
})

export default function ProductsSustainability({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query ProductsSustainabilityContent {
      heroImage: file(
        relativePath: { eq: "about-sustainability/sustainability_hero.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImage: file(
        relativePath: {
          eq: "about-sustainability/sustainability_plastic_recycling.jpg"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      petImage: file(
        relativePath: {
          eq: "about-sustainability/Polyethylene_Terephthalate_PET_waste_bottls.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rubberImage: file(
        relativePath: {
          eq: "about-sustainability/Industrial_landfill_for_the_processing_of_waste_tires.png"
        }
      ) {
        childImageSharp {
          fluid(quality: 90, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const heroImage = data.heroImage.childImageSharp.fluid
  const bannerImage = data.bannerImage.childImageSharp.fluid
  const petImage = data.petImage.childImageSharp.fluid
  const rubberImage = data.rubberImage.childImageSharp.fluid

  content.section1.cards[0].imageData = petImage
  content.section1.cards[1].imageData = rubberImage

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    new RegExp("-", "g"),
    " "
  )

  return (
    <>
      <Layout location={location}>
      <SEO title={content.seo.title} />
        <Hero
          imageData={heroImage}
          textTitle={content.hero.heading}
          textDescription={content.hero.description}
          dark={true}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.headerContainer}>
              <Typography className={classes.header} variant="body1">
                {content.header.description}
              </Typography>
            </div>
          </div>
        </PageWrapper>
        <SubsectionHero
          imageData={bannerImage}
          imageAlt={""}
          textTitle={content.section1.heading}
          textDescription={content.section1.description}
        />
        <PageWrapper>
          <div className={classes.root}>
            <Grid
              className={classes.gridContainer}
              container
              justify="center"
              direction="row"
              alignItems="center"
              spacing={1}
            >
              {content.section1.cards.map((card, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <CardVertical
                    title={card.heading}
                    description={card.description}
                    imageData={card.imageData}
                    imgAlt={""}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </PageWrapper>
        <HeroBackgroundColor
          textTitle={content.section2.heading}
          textDescription={content.section2.description}
        />
      </Layout>
    </>
  )
}
