import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "0 auto",
    display: "block",
  },
  img: {
    height: "100%",
    borderRadius: "4px",
    zIndex: 0,
  },
  containerWrapper: {
    maxWidth: "1440px",
    margin: "0 auto",
  },
  textContainer: {
    borderRadius: "4px",
    zIndex: 3,
    width: "85%",
    marginLeft: "7.5%",
    backgroundColor: "#fff",
    padding: "60px 90px 80px 90px",
    position: "relative",
    marginTop: "-80px",
    "@media (max-width:768px)": {
      width: "90%",
      padding: "24px 24px 44px 24px",
      zIndex: 3,
    },
  },
  textBody: {
    marginTop: "16px",
  },
})

const SubsectionHero = ({
  imageData,
  imageAlt,
  textTitle,
  textDescription,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Img className={classes.img} fluid={imageData} />
      <div className={classes.containerWrapper}>
        <div className={classes.textContainer}>
          <Typography variant="h3">{textTitle}</Typography>
          <Typography className={classes.textBody} variant="body1">
            {textDescription}
          </Typography>
        </div>
      </div>
    </div>
  )
}

SubsectionHero.defaultProps = {
  imageData: {},
  imageAlt: ``,
  textTitle: `SubsectionHero Title Placeholder`,
  textDescription: `SubsectionHero description placeholder.`,
}

SubsectionHero.propTypes = {
  imageData: PropTypes.object.isRequired,
  imageAlt: PropTypes.string,
  textTitle: PropTypes.string.isRequired,
  textDescription: PropTypes.string.isRequired,
}

export default SubsectionHero
